import axios from 'axios';

/**
 * Creates an Axios instance with the base URL set to the provided baseUrl.
 *
 * @param baseUrl - The base URL for the Axios instance
 * @returns An Axios instance configured with the base URL
 */

const getApiBaseUrl = (): string => {
  return window.location.origin; // Returns the current application's base URL
};

const isRelativeUrl = (url: string): boolean => {
  return url.startsWith('/');
};

const removeTrailingSlashes = (url: string): string => {
  return url.replace(/\/+$/, '');
};

const axiosPublic = (baseUrl: string) => {
  if (isRelativeUrl(baseUrl)) {
    baseUrl = removeTrailingSlashes(getApiBaseUrl()) + baseUrl;
  }

  return axios.create({
    baseURL: baseUrl, // Base URL for API requests
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export default axiosPublic;
