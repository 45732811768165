// src/app/login/page.tsx
'use client';

import { useEffect, useState } from 'react';
import { useAuthStore } from '@/store/authStore';
import { useRouter } from 'next/navigation';

export default function LoginPage() {
  const router = useRouter();
  const { login, user, loading, error, clearError } = useAuthStore();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async () => {
    await login(email, password);
  };

  useEffect(() => {
    if (user) {
      router.push('/landing'); // Redirect to the home page or any other route
    }
  }, [user, router]);

  // Clear error when the component unmounts or if the user changes input
  useEffect(() => {
    return () => {
      clearError();
    };
  }, [clearError]);

  return (
    <div>
      <h1>Login</h1>
      {loading && <p>Loading...</p>}
      {user ? (
        <p>Redirecting...</p>
      ) : (
        <form className="login-form" onSubmit={(e) => e.preventDefault()}>
          <div>
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                clearError();
              }}
              required
              aria-required="true"
              aria-invalid={!!error}
              aria-describedby={error ? 'error-message' : undefined}
            />
          </div>
          <div>
            <label htmlFor="password">Password:</label>
            <input
              type="password"
              placeholder="Password"
              id="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                clearError();
              }}
              required
              aria-required="true"
              aria-invalid={!!error}
              aria-describedby={error ? 'error-message' : undefined}
            />
          </div>
          {error && (
            <div
              id="error-message"
              role="alert"
              aria-live="assertive"
              className="error-message"
            >
              {error}
            </div>
          )}
          <button type="button" onClick={handleLogin}>
            Login
          </button>
        </form>
      )}
    </div>
  );
}
